@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap');

* {
    font-family: 'Poppins', sans-serif;
} */

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700&display=swap');

* {
    font-family: 'Noto Sans', sans-serif;
}

body {
    padding: 0;
    box-sizing: border-box;
    margin: 0;
    text-decoration: none;
    list-style: none;
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

.z-minus {
    z-index: -1;
}

.brightness-40 {
    --tw-brightness: brightness(.4);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

audio::-webkit-media-controls-timeline,
video::-webkit-media-controls-timeline {
    display: none;
}
audio::-webkit-media-controls,
video::-webkit-media-controls {
    display: none;
}

.nature {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.scrolled {
    background-color: white;
    color: black;
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.scrolled-u {
    color: black;
}

.gradient-fade {
    background-image: linear-gradient(
    to bottom,
    rgb(255, 255, 255) 0%,
    rgba(255, 255, 255, 0) 3%,
    rgba(255, 255, 255, 0) 90%,
    rgb(255, 255, 255) 97%
);
}